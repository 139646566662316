.tg-logo img {
  height: 45px;
  width: auto; }

@media (max-width: 767px) {
  .tg-logo, .tg-logo img {
    width: 250px;
    height: auto; } }

@media (min-width: 768px) and (max-width: 991px) {
  .tg-logo, .tg-logo img {
    width: 250px;
    height: auto; } }

.home .tg-main {
  padding-bottom: 0; }

.home .banner-label {
  background: rgba(255, 255, 255, 0.7);
  padding: 20px; }
  @media (min-width: 767px) {
    .home .banner-label {
      margin-right: 0; }
      .home .banner-label p {
        line-height: 24px; }
      .home .banner-label .tg-homeslider figure figcaption h2 {
        padding: 0 0 45px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .home .banner-label {
      margin-bottom: 85px; }
      .home .banner-label .tg-homeslider figure figcaption h2 {
        padding: 0 0 25px; } }

.home .industries .tg-project figure figcaption {
  transform: translateY(0);
  background: rgba(0, 66, 129, 0.7); }
  .home .industries .tg-project figure figcaption h4 {
    font-size: 16px; }

.home .contact .send {
  margin-top: 20px; }

.home .contact textarea {
  height: 150px; }

.home .contact-numbers {
  padding: 0; }
  .home .contact-numbers ul {
    color: #515151; }
  .home .contact-numbers h3, .home .contact-numbers h4 {
    color: #004281; }
  @media (max-width: 767px) {
    .home .contact-numbers {
      margin-top: 20px; } }

.tg-footerbottombar {
  color: #fff; }

@media (max-width: 767px) {
  .tg-homeslider .owl-item {
    float: none; } }

footer .tg-logo img {
  height: auto; }

.action-link {
  color: #004281; }

.bordered-link {
  border: #004281 solid 1px;
  color: #004281;
  padding: 7px 20px;
  line-height: 30px;
  border-radius: 2px;
  text-transform: uppercase; }

.tg-service .tg-description p {
  margin-bottom: 20px; }

.tg-heading h4 {
  line-height: 24px; }

.tg-service {
  padding: 20px; }

.services .tg-description p {
  text-indent: 50px;
  margin-bottom: 20px; }

.services .tg-pagecontent {
  padding: 50px 0; }

.address-block {
  margin-top: 30px !important; }

footer address {
  font-size: normal; }
  footer address strong {
    color: #fff; }

.tg-member.tg-detailpage .tg-memberinfo {
  border: none; }

.team .table tr td {
  text-align: left;
  padding: 10px; }
  .team .table tr td ul {
    margin-bottom: 20px; }

/*# sourceMappingURL=app.css.map */
