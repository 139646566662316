*:after,
*:before,
p a,
a,
.tg-btn,
.tg-btn span,
.tg-header,
.tg-navigationarea,
.tg-logo,
.tg-nav,
.tg-navigation ul li,
.tg-hasdropdown ul,
.mega-menu,
.tg-homeslider .owl-controls .owl-buttons div,
.tg-service,
.tg-seviceicon,
.owl-theme .owl-controls .owl-page span,
.tg-skill h4,
.tg-skill h5,
.tg-skillbar,
.tg-skillholder,
.tg-member figure img,
.tg-member figure a img,
.tg-member figure figcaption,
.tg-socialprofilelinks li a,
.tg-themetabnav ul li a,
.tg-project figure img,
.tg-project figure figcaption,
.tg-testimonial,
.tg-clientinfo,
.tg-brand,
.tg-widget.tg-widgetflicker ul li,
.tg-whatwedoicon,
.tg-images figure .tg-box img,
.tg-images figcaption,
.tg-btndownload,
.tg-brochure,
.tg-event figure a img,
.tg-event figure figcaption,
.tg-news figure img,
.tg-news figure figcaption,
.tg-newsmetadata li a,
.tg-newstitle h2 a,
.tg-btnview,
.tg-getdirection,
.tg-panel h4{
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}
.owl-origin {
	-webkit-perspective: 1200px;
	-webkit-perspective-origin-x : 50%;
	-webkit-perspective-origin-y : 50%;
	-moz-perspective : 1200px;
	-moz-perspective-origin-x : 50%;
	-moz-perspective-origin-y : 50%;
	perspective : 1200px;
}
/* fade */
.owl-fade-out {
	z-index: 10;
	-webkit-animation: fadeOut .7s both ease;
	-moz-animation: fadeOut .7s both ease;
	animation: fadeOut .7s both ease;
}
.owl-fade-in {
	-webkit-animation: fadeIn .7s both ease;
	-moz-animation: fadeIn .7s both ease;
	animation: fadeIn .7s both ease;
}
/* backSlide */
.owl-backSlide-out {
	-webkit-animation: backSlideOut 1s both ease;
	-moz-animation: backSlideOut 1s both ease;
	animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
	-webkit-animation: backSlideIn 1s both ease;
	-moz-animation: backSlideIn 1s both ease;
	animation: backSlideIn 1s both ease;
}
/* goDown */
.owl-goDown-out {
	-webkit-animation: scaleToFade .7s ease both;
	-moz-animation: scaleToFade .7s ease both;
	animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
	-webkit-animation: goDown .6s ease both;
	-moz-animation: goDown .6s ease both;
	animation: goDown .6s ease both;
}
/* scaleUp */
.owl-fadeUp-in {
	-webkit-animation: scaleUpFrom .5s ease both;
	-moz-animation: scaleUpFrom .5s ease both;
	animation: scaleUpFrom .5s ease both;
}
.owl-fadeUp-out {
	-webkit-animation: scaleUpTo .5s ease both;
	-moz-animation: scaleUpTo .5s ease both;
	animation: scaleUpTo .5s ease both;
}
/* Keyframes */
/*empty*/
@-webkit-keyframes empty {0% {opacity: 1}}
@-moz-keyframes empty {0% {opacity: 1}}
@keyframes empty {0% {opacity: 1}}
@-webkit-keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@-moz-keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@-webkit-keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@-moz-keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
	25% { opacity: .5; -webkit-transform: translateZ(-500px); }
	75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
	25% { opacity: .5; -moz-transform: translateZ(-500px); }
	75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
	25% { opacity: .5; transform: translateZ(-500px); }
	75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
	0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; -webkit-transform: translateZ(-500px); }
	100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
	0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; -moz-transform: translateZ(-500px); }
	100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
	0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; transform: translateZ(-500px); }
	100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
@-webkit-keyframes scaleToFade {to { opacity: 0; -webkit-transform: scale(.8); }}
@-moz-keyframes scaleToFade {to { opacity: 0; -moz-transform: scale(.8); }}
@keyframes scaleToFade {to { opacity: 0; transform: scale(.8); }}
@-webkit-keyframes goDown {from { -webkit-transform: translateY(-100%); }}
@-moz-keyframes goDown {from { -moz-transform: translateY(-100%); }}
@keyframes goDown {from { transform: translateY(-100%); }}
@-webkit-keyframes scaleUpFrom {from { opacity: 0; -webkit-transform: scale(1.5); }}
@-moz-keyframes scaleUpFrom {from { opacity: 0; -moz-transform: scale(1.5); }}
@keyframes scaleUpFrom {from { opacity: 0; transform: scale(1.5); }}
@-webkit-keyframes scaleUpTo {to { opacity: 0; -webkit-transform: scale(1.5); }}
@-moz-keyframes scaleUpTo {to { opacity: 0; -moz-transform: scale(1.5); }}
@keyframes scaleUpTo {to { opacity: 0; transform: scale(1.5); }}