/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-theme .owl-controls{
	padding:15px 0 0;
	text-align: center;
}
.owl-pagination{
	width:100%;
	float:left;
	line-height:12px;
}
/* Styling Next and Prev buttons */
.owl-theme .owl-controls .owl-buttons div{
	color: #fff;
	zoom: 1;
	margin: 5px;
	padding: 3px 10px;
	font-size: 12px;
	display: inline-block;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background: #869791;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{text-decoration: none;}
/* Styling Pagination*/
.owl-theme .owl-controls .owl-page{
	zoom: 1;
	display: inline-block;
	vertical-align:top;
}
.owl-theme .owl-controls .owl-page span{
	width: 12px;
	height: 12px;
	margin:0 5px;
	display: block;
	background: #ddd;
	border:2px solid #ddd;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{ background:#fff;}
/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}
/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center
}