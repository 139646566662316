/*
 @Author: Company Name
 @URL: http://companyname.com
 This file contains the styling for the actual theme, this
 is the file you need to edit to change the look of the
 theme.
 However we used Less script to make things easy to maintain, this is the compressed ourput, we recommend to edit the less files and then recombile the files and add the result here, if you don't like to use less you can edit this file here.
 
 This files contents are outlined below.
 01.	Theme Reset Style
 02.	Global Elements
 03.	Menu
 04.	Social
 05.	Article
 06.	Posts
 07.	Responsive
 08.	Widgets
 09.	Flexslider
 10.	Grid
*/

/* =============================================
			Theme Reset Style
============================================= */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,600|Montserrat:400,700);
* {
	margin: 0px;
	padding: 0px;
}
html,
body {
	position: relative;
	height: 100%;
}
body {
	color: #515151;
	background: #fff;
	overflow-x: hidden;
	font:400 14px/20px 'Open Sans', Arial, Helvetica, sans-serif;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
	color: #515151;
	margin: 0 0 8px;
	font-weight: 400;
	line-height: 1.5;
	font-style: normal;
	text-transform:uppercase;
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}
h1 { font-size: 48px;}
h2 { font-size: 36px;}
h3 { font-size: 18px;}
h4 { font-size: 16px;}
h5 { font-size: 14px;}
h6 { font-size: 12px;}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a { color: #515151;}
p a {}
p a:hover {text-decoration:underline;}
p {
	letter-spacing: normal;
	line-height: 20px;
	margin: 0 0 20px;
}
a {text-decoration: none;}
a:hover, a:focus, a:active {
	outline: none;
	text-decoration: none;
}
ul { margin: 0; }
figure {
	margin-bottom: 6px;
	position: relative;
}
img {
	height: auto;
	max-width: 100%;
}
strong { color: #414141; }
iframe {
	border: none;
	float: left;
	width: 100%;
}
.wp-caption,
.wp-caption-text,
.gallery-caption,
.bypostauthor {}
.wp-caption.alignleft,
.wp-caption.alignnone { width: 100%; }
button { border: none; }
textarea,
select,
.tg-select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	outline: none;
	color: #666;
	height: 50px;
	background:#fff;
	font-size: 14px;
	line-height: 20px;
	padding: 15px 20px;
	display: inline-block;
	vertical-align: middle;
	border-radius: 3px;
	box-shadow: none;
	border: 1px solid #e1e1e1;
	/*text-transform: capitalize;*/
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.form-group{ margin:0 0 20px;}
.form-control::-moz-placeholder{
	/*text-transform: capitalize;*/
	color: #999;
}
.tg-select select:focus,
.form-control:focus{
	border-color: #999 !important;
	box-shadow:none !important;
}
/*=======================
	Custom Select
=======================*/
.tg-select{
	width: 100%;
	float:left;
	color:#999;
	position: relative;
	text-transform:uppercase;
}
.tg-select:after{
	content: '\f107';
	top: 50%;
	right: 23px;
	z-index: 2;
	width: 13px;
	color: #999;
	display: block;
	font-size: 22px;
	margin-top: -5px;
	line-height: 10px;
	position: absolute;
	text-align: center;
	font-family: 'FontAwesome';
}
.tg-select select {
	width: 100%;
	z-index: 1;
	color:#999;
	height:50px;
	padding:15px;
	line-height:20px;
	position: relative;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.tg-select select option{color:#999;}
/*=======================
	Custom CheckBox
=======================*/
.tg-radio,
.tg-radio label,
.tg-checkbox,
.tg-checkbox label{
	width: 100%;
	float: left;
	margin:0;
	position: relative;
}
.tg-radio label,
.tg-checkbox label{
	cursor:pointer;
	display:block;
	padding:0 0 0 20px;
}
.tg-radio input[type=radio],
.tg-checkbox input[type=checkbox] { display:none; }
.tg-radio input[type=radio] + label:before,
.tg-checkbox input[type=checkbox] + label:before {
	top: 4px;
	left: 0;
	color: #373542;
	font-size: 14px;
	line-height: 14px;
	content: "\f096";
	position: absolute;
	font-family: 'FontAwesome';
}
.tg-radio input[type=radio] + label:before{ content:'';}
.tg-radio input[type=radio]:checked + label:before,
.tg-checkbox input[type=checkbox]:checked + label:before {content:'\f046';}
/*=======================
	IOS Style CheckBox
=======================*/
.tg-iosstylcheckbox{
	width:80px;
	float:left;
	position:relative;
}
.tg-iosstylcheckbox input[type=checkbox],
.tg-iosstylcheckbox input[type=checkbox]:active {display:none;}
.tg-iosstylcheckbox label {
	margin:0;
	width: 100%;
	height: 24px;
	display: block;
	overflow:hidden;
	cursor: pointer;
	font-size: 12px;
	line-height: 24px;
	position: relative;
	background: #ddd;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
}
.tg-iosstylcheckbox label:before {
	top: 5px;
	left: 5px;
	z-index: 1;
	height: 14px;
	width: 14px;
	content: '';
	background:#373542;
	display: block;
	line-height: 14px;
	position: absolute;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
}
.tg-iosstylcheckbox label:after {
	top:0;
	left:0;
	z-index: 0;
	width:100%;
	height: 100%;
	color: #fff;
	display: block;
	font-size:13px;
	line-height:24px;
	text-align: right;
	padding:0 12px 0 0;
	position: absolute;
	white-space: nowrap;
	content: attr(data-private);
}
.tg-iosstylcheckbox input[type=checkbox]:checked + label:before {
	left: calc(100% - 19px);
	background:#373542;
}
.tg-iosstylcheckbox input[type=checkbox]:checked + label:after {
	padding:0 31px 0 0;
	content:attr(data-public);
}
label {
	color: #414141;
	display: block;
	font-weight: 400;
	margin-bottom: 10px;
}
form p {
	float: left;
	position: relative;
	width: 100%;
}
form p span i {
	color: #474747;
	left: 16px;
	position: absolute;
	top: 13px;
}
option { padding: 5px 15px; }
li {
	line-height: 28px;
	list-style: disc inside none;
}
ul ul { margin-left: 20px; }
ol {
	float: none;
	list-style: decimal;
	padding-left: 15px;
}
ol ol { margin-left: 20px; }
ol li {
	list-style: decimal;
	width: 100%;
}
table {
	background-color: transparent;
	max-width: 100%;
}
th { text-align: left; }
table {
	margin-bottom: 15px;
	width: 100%;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	border-top: 1px solid #d2d2d2;
	border: 1px solid #d2d2d2;
	line-height: 2.5;
	padding-left: 3px;
	text-align: center;
	vertical-align: top;
}
table thead tr th {
	border-top: 1px solid #d2d2d2;
	text-align: center;
	/*text-transform: capitalize;*/
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td { border-top: 1px solid #d2d2d2; }
table > thead > tr > th {
	border-bottom: 2px solid #d2d2d2;
	vertical-align: bottom;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td { border-bottom: 0; }
table > tbody + tbody { border-top: 2px solid #d2d2d2; }
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td { padding: 6px 10px; }
p ins { color: #999; }
dl dd { margin-left: 20px; }
address { font-style: italic; }
/*=============================================
			Global Elements
=============================================*/
.tg-haslayout{
	width:100%;
	float:left;
}
.tg-main-section{padding: 80px 0;}
.tg-sectionhead{
	width:100%;
	float:left;
	padding:0 0 25px;
	text-align:center;
}
.tg-sectiontitle{
	padding:0 0 40px;
	vertical-align:top;
	display:inline-block;
	text-align:left;
	overflow:hidden;
}
.tg-sectiontitle h2{
	color:#999;
	text-align:left;
	font-size:16px;
	line-height:13px;
	margin:0 0 10px;
	padding:0 15px 0 0;
	display:inline-block;
	position:relative;
}
.tg-sectiontitle h2:before{
	top:50%;
	left:100%;
	width:999px;
	height:1px;
	content:'';
	background:#999;
	position:absolute;
}
.tg-sectiontitle h3{
	margin:0;
	text-align:left;
	font-size:30px;
	line-height:23px;
}
.tg-sectionhead .tg-description{ padding:0 0 25px;}
.tg-sectionhead .tg-description p{ margin:0;}
.tg-location-map{
	width: 100%;
	float: left;
	height: 170px;
}
.tg-heading{
	width:100%;
	float:left;
	position:relative;
	padding:30px 0 20px;
}
.tg-heading:before{
	top:0;
	left:50%;
	width:30px;
	height:2px;
	content:'';
	background:#ddd;
	position:absolute;
	margin:0 0 0 -15px;
}
.tg-heading h4{
	margin:0;
	font-size:18px;
	line-height:14px;
}
.tg-heading h4 a{ display:block;}
.gmnoprint .gm-style-cc,
.gmnoprint.gm-style-cc{ display:none;}
.tg-btn-sm{
	padding: 0 15px;
	line-height: 30px;
}
.tg-btn{
	color: #fff;
	padding: 0 35px;
	position:relative;
	text-align:center;
	border:2px solid;
	border-radius:3px;
	display: inline-block;
	vertical-align: middle;
	background: transparent;
	text-transform: uppercase;
	font:400 14px/50px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-btn + .tg-btn{ margin:0 0 0 30px;}
.tg-btn:after{
	top:0;
	left:0;
	content:'';
	z-index:1;
	width:0;
	height:100%;
	position:absolute;
}
.tg-btn span{
	z-index:2;
	display:block;
	padding:0 15px;
	position:relative;
}
.tg-btn span:before{
	top:0;
	left:0;
	opacity:0;
	height:100%;
	content:'\f105';
	font-size:18px;
	line-height:46px;
	position:absolute;
	visibility:hidden;
	font-family:'FontAwesome';
}
.tg-btn:hover{color:#fff;}
.tg-btn:hover:after,
.tg-btn.active:after{width:100%;}
.tg-btn:hover span:before{
	left:100%;
	opacity:1;
	color:#fff;
	visibility:visible;
}
.tg-btn-lg{ width: 100%;}
.tg-socialicons{
	overflow: hidden;
	list-style: none;
	text-align:center;
	line-height: 20px;
}
.tg-socialicons li{
	float: left;
	padding: 0 4px;
	line-height: inherit;
	list-style-type: none;
}
.tg-socialicons li a{
	width:26px;
	height:26px;
	color: #fff;
	display:block;
	overflow:hidden;
	border-radius:2px;
	border:1px solid #fff;
}
.tg-socialicons li a i{
	display:block;
	font-size:13px;
	line-height:24px;
	text-align:center;
}
.tg-socialicons li a:hover{background:#fff;}
.tg-verticalmiddle{
	margin: 0 -1px;
	float: none !important;
	display: inline-block;
	vertical-align: middle;
}
.tg-description{
	width:100%;
	float:left;
}
.tg-bglight{ background:#f7f7f7;}
.tg-bgdark{ background:#373542;}
.tg-stars span,
.tg-stars{
	width:100px;
	height: 16px;
	float: left;
	line-height:16px;
	position:relative;
}
.tg-stars span:after,
.tg-stars:before {
	top: 0;
	left: 0;
	font-size: 16px;
	line-height: 16px;
	position: absolute;
	letter-spacing: 4px;
	color: rgba(218, 218, 218, .8);
	font-family: 'FontAwesome';
	content: '\f005 \f005 \f005 \f005 \f005';
}
.tg-stars span {
	width:75%;
	overflow: hidden;
}
.tg-stars span:after {color:#000;}
.row.no-gutters{
	margin-right: 0;
	margin-left: 0;
}
.no-gutters > [class^="col-"],
.no-gutters > [class*="col-"],
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*="col-"]{
	padding-right: 0;
	padding-left: 0;
}
.tg-parallax-window{
	overflow:hidden;
	position:relative;
}
.tg-parallax-window:after{
	top:0;
	left:0;
	content:'';
	z-index:-1;
	width:100%;
	height:100%;
	opacity:0.60;
	position:absolute;
}
.tg-shadow{box-shadow: 0 0 20px 5px rgba(0,0,0,0.7);}
.wow{visibility: hidden;}
/*=============================================
		Theme Pagination
=============================================*/
.tg-pagination{
	width:100%;
	float: left;
	font-size:14px;
	line-height:25px;
	padding:30px 0 0;
	border-top:2px solid #ddd;
	font:14px/20px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-pagination ul{
	float:right;
	margin:0;
	padding:0;
	list-style:none;
	text-align: center;
	line-height: inherit;
}
.tg-pagination ul li{
	float:left;
	margin:0 6px;
	line-height: inherit;
	list-style-type:none;
}
.tg-pagination ul li.tg-prevpage{ margin-left:0;}
.tg-pagination ul li.tg-nextpage{ margin-right:0;}
.tg-pagination ul li a{
	width:20px;
	height:20px;
	display:block;
	color:#373542;
	text-align:center;
	position:relative;
	line-height:18px;
	border-radius:3px;
	border:1px solid #fff;
}
.tg-pagination ul li.tg-nextpage a,
.tg-pagination ul li.tg-prevpage a{
	font-size: 14px;
	overflow:hidden;
	position:relative;
	border:1px solid #515151;
}
.tg-pagination ul li.tg-nextpage a:hover,
.tg-pagination ul li.tg-prevpage a:hover{background:#515151;}
.tg-pagination ul li.tg-nextpage a:hover i,
.tg-pagination ul li.tg-prevpage a:hover i{ color:#fff;}
/*=============================================
		Theme BreadCrumb
=============================================*/
.tg-breadcrumb {
	margin:0;
	padding: 0;
	float: right;
	color: #515151;
	list-style: none;
	text-align: right;
	text-transform: uppercase;
	font:14px/18px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-breadcrumb li{
	width:auto;
	float:left;
	padding:0 15px;
	position:relative;
	line-height:inherit;
	list-style-type:none;
}
.tg-breadcrumb li:after{
	top:0;
	left:-3px;
	content:'\f105';
	position:absolute;
	font-family:'FontAwesome';
}
.tg-breadcrumb li:last-child{ padding: 0 0 0 15px;}
.tg-breadcrumb li:first-child:after{display: none;}
.tg-breadcrumb li a{
	display:block;
	color:#515151;
}
.tg-prev,
.tg-next{
	top:0;
	right:0;
	font-size:14px;
	cursor:pointer;
	position:absolute;
}
.tg-prev span,
.tg-next span{
	color:#373542;
	display:block;
	line-height:16px;
	margin:0 14px 0 0;
}
.tg-prev{
	top:-2px;
	right:40px;
	text-align:left;
}
.tg-next{
	text-align:right;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.tg-prev span:after,
.tg-next span:after{
	content:'';
	top:50%;
	left:15px;
	width:20px;
	height:1px;
	z-index:-1;
	opacity:0;
	background:#373542;
	position:absolute;
	visibility:hidden;
}
.tg-prev:hover span:after,
.tg-next:hover span:after{
	opacity:1;
	visibility:visible;
}
.tg-prev:hover span:after,
.tg-next:hover span:after{left:3px;}
/*=============================================
			Theme Style
=============================================*/
body.tg-home{}
.tg-wrapper{}
.tg-twocolumns{
	width:100%;
	float:left;
}
.tg-content{
	width:100%;
	float:left;
}
.tg-siderbar{
	width:100%;
	float:left;
}


/*=============================================
			Header
=============================================*/
body.tg-fixednav .tg-header{ margin:0;}
.tg-header{
	z-index:999;
	margin:0 0 -132px;
	position:relative;
	box-shadow: 0 0 15px 5px rgba(0,0,0,.1);
}
/*=============================================
			Header TopBar
=============================================*/
.tg-topbar{
	width:100%;
	float:left;
	overflow:hidden;
}
.tg-addnav ul,
.tg-topcontactinfo{
	float:left;
	padding:10px 0;
	list-style:none;
	line-height:17px;
}
.tg-addnav ul li,
.tg-topcontactinfo li{
	float:left;
	padding:0 10px;
	position:relative;
	line-height:inherit;
	list-style-type:none;
}
.tg-addnav ul li + li:before,
.tg-topcontactinfo li + li:before{
	left:-1px;
	bottom:3px;
	width:2px;
	height:2px;
	content:'';
	position:absolute;
	background:#515151;
}
.tg-addnav ul li:first-child,
.tg-topcontactinfo li:first-child{padding-left:0;}
.tg-topcontactinfo li i,
.tg-topcontactinfo li span,
.tg-topcontactinfo li address{
	margin:0;
	font-size:14px;
	line-height:17px;
	font-style:normal;
	display:inline-block;
	vertical-align:middle;
}
.tg-topcontactinfo li span a{
	display:block;
	color:#515151;
}
.tg-addnav{
	float:right;
	position:relative;
	padding:1px 0 2px 30px;
}
.tg-addnav:before{
	top:0;
	left:100%;
	width:9999px;
	height:100%;
	content:'';
	position:absolute;
}
.tg-addnav:after{
	top:0;
	width: 0;
	height: 0;
	right:100%;
	content:'';
	position:absolute;
	border-top: 40px solid;
	border-left: 30px solid transparent !important;
}
.tg-addnav ul li + li:before{ background:#fff;}
.tg-addnav ul li a{ color:#fff;}
.tg-addnav ul li:last-child{ padding-right:0;}
/*=============================================
			Header Navigation
=============================================*/
body.tg-fixednav .tg-navigationarea{
	top:0;
	left:0;
	position:fixed;
	background:rgba(255, 255, 255, 1);
}
.tg-navigationarea{
	width:100%;
	float:left;
	border-bottom:3px solid transparent;
	background:rgba(255, 255, 255, 0.80);
}
body.tg-fixednav .tg-logo{ margin:20px 0;}
.tg-logo{
	float:left;
	margin:43px 0;
}
.tg-logo a,
.tg-logo a img{ display:block;}
body.tg-fixednav .tg-nav{ padding:25px 0 0;}
.tg-dropdowarrow{ display:none;}
.tg-nav{
	float:right;
	padding:48px 0 0;
	text-transform:uppercase;
	font:400 14px/30px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-navigation{
	width:100%;
	float:left;
	padding:0;
}
.tg-navigation > ul{
	width:100%;
	float:left;
	list-style:none;
	line-height:inherit;
}
body.tg-fixednav .tg-navigation > ul > li{ padding:0 0 25px;}
.tg-navigation > ul > li{
	float:left;
	padding:0 0 48px;
}
.tg-navigation ul li{
	position:relative;
	line-height:inherit;
	list-style-type:none;
}
.tg-navigation > ul > li > a{
	padding:0 20px;
	line-height:30px;
	border-radius:2px;
	border:1px solid transparent;
}
.tg-navigation ul li a{display:block;}
.tg-navigation > ul > li > a > i{font-style:normal;}
.tg-navigation > ul > li > a > i + i{ display:none;}
.tg-hasdropdown{position:relative;}
.tg-hasdropdown ul{
	top:150%;
	left:0;
	margin:0;
	opacity:0;
	width:230px;
	background:#fff;
	border-radius:3px;
	position:absolute;
	visibility:hidden;
	border:1px solid #ddd;
}
.tg-hasdropdown:nth-last-child(1) ul,
.tg-hasdropdown:nth-last-child(2) ul{
	left:auto;
	right:0;
}
.tg-navigation ul li ul{ font-size:13px;}
.tg-navigation ul li ul li a{ color:#999;}
.tg-hasdropdown ul li a{
	border:0;
	padding:0 20px;
	border-radius:0;
	background:#fff;
	line-height:38px;
	position:relative;
	border-top:1px solid #ddd;
}
.tg-hasdropdown ul li a:before{
	top:0;
	left:0;
	width:4px;
	height:0;
	content:'';
	position:absolute;
}
.tg-hasdropdown ul li:first-child a{ border:0;}
.tg-hasdropdown ul li + li a,
.tg-hasdropdown ul li ul li + li a,
.tg-hasdropdown ul li ul li ul li + li a{}
.tg-hasdropdown ul li.tg-hasdropdown ul{left:100%;}
.tg-hasdropdown > ul > li:hover > a,
.tg-hasdropdown > ul > li > a:hover{background:#fbfbfb;}
.tg-hasdropdown > ul > li > a:hover:before,
.tg-hasdropdown > ul > li:hover > a:before{ height:100%;}
.mega-menu{
	top:150%;
	left:-508px;
	width:1170px;
	opacity:0;
	overflow:hidden;
	background:#fff;
	position:absolute;
	visibility:hidden;
	border:1px solid #ddd;
}
.tg-navigation > ul > li.tg-hasdropdown:hover > ul,
.tg-navigation > ul > li > ul > li.tg-hasdropdown:hover > ul,
.tg-navigation > ul > li > ul > li > ul > li.tg-hasdropdown:hover > ul,
.tg-navigation ul li.menu-item-has-mega-menu:hover .mega-menu{
	top:100%;
	opacity:1;
	visibility:visible;
}
.tg-hasdropdown > ul > li.tg-hasdropdown:hover > ul,
.tg-navigation > ul > li > ul > li.tg-hasdropdown:hover > ul,
.tg-navigation > ul > li > ul > li > ul > li.tg-hasdropdown:hover > ul{ top:0;}
.mega-menu-row{
	margin:0;
	width:100%;
	display:table;
	table-layout:fixed;
}
.mega-menu-col{
	padding:30px;
	display:table-cell;
	vertical-align:top;
}
.mega-menu-col a{
	float:left;
	position:relative;
	font:400 13px/17px 'Montserrat', Arial, Helvetica, sans-serif;
}
.mega-menu-col a.fa{padding:0 0 0 15px;}
.mega-menu-col a.fa:before{
	left:0;
	position:absolute;
	font-family:'FontAwesome';
}
.mega-menu-col ul{
	width:100%;
	float:left;
	margin:0;
}
.mega-menu-col ul li{
	width:100%;
	float:left;
}
.tg-navigation .mega-menu-col > a{
	color:#515151;
	font-size:18px;
	line-height:18px;
	margin:0 0 20px;
	position:relative;
}
.mega-menu-col > a:after{
	bottom:-20px;
	left:0;
	width:30px;
	height:2px;
	content:'';
	background:#ddd;
	position:absolute;
}
.mega-menu-col:last-child{background:#fbfbfb;}
.mega-menu-col:last-child > a{width:100%;}
.mega-menu-col:last-child > a:after{ width:100%;}
.mega-menu-col ul{ padding:10px 0 0;}
.mega-menu-col ul li{
	padding:10px 0 0;
	line-height:17px;
}
.mega-menu-col ul li i,
.mega-menu-col ul li address,
.mega-menu-col ul li span,
.mega-menu-col ul li span a{
	margin:0;
	color:#515151 !important;
	font-size:13px;
	line-height:17px;
	font-style:normal;
	text-transform:none;
	display:inline-block;
	vertical-align:top;
	font-family:'Open Sans', Arial, Helvetica, sans-serif;
}
.mega-menu-col ul li i{
	padding:0 15px 0 0;
	font-family:'FontAwesome';
}
.mega-menu-col ul li address{ width:82%;}
/*=============================================
			Home Slider
=============================================*/
.tg-homeslider .owl-controls{
	width:100%;
	float:left;
	margin:0;
}
.tg-homeslider .owl-controls .owl-buttons div{
	top:50%;
	padding:0;
	opacity:1;
	width:70px;
	height:70px;
	color:#515151;
	display:block;
	margin:-35px 0 0;
	line-height:70px;
	border-radius:50%;
	position:absolute;
	background:transparent;
	border:2px solid #515151;
}
.tg-homeslider .owl-controls .owl-buttons div:hover i{ color:#fff;}
.tg-homeslider .owl-controls .owl-buttons div i{
	display:block;
	font-size:30px;
	line-height:66px;
	text-align:center;
}
.tg-homeslider .owl-controls .owl-buttons div.owl-prev{ left:20px;}
.tg-homeslider .owl-controls .owl-buttons div.owl-next{ right:20px;}
.tg-homeslider{
	z-index:1;
	position:relative;
}
.tg-homeslider figure{
	width:100%;
	float:left;
	margin:0;
	position:relative;
}
.tg-homeslider figure img{
	z-index:1;
	display:block;
	position:relative;
}
.tg-homeslider figure figcaption{
	top:0;
	left:0;
	z-index:2;
	width:100%;
	height:100%;
	padding:132px 0 0;
	position:absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	text-align:center;
}
.tg-homeslider figure figcaption h1{
	margin:0;
	font-size:30px;
	line-height:30px;
}
.tg-homeslider figure figcaption h2{
	margin:0;
	font-size:90px;
	line-height:90px;
	padding:0 0 70px;
	position:relative;
}
.tg-homeslider figure figcaption .tg-description{
	padding:0 20px;
	font-size:16px;
	line-height:22px;
}
.tg-homeslider figure figcaption .tg-description p{ margin:0 0 20px;}
.tg-svginject{
	top:35%;
	right:20px;
	position:absolute;
}
/*=============================================
			Main
=============================================*/
.tg-main{padding:20px 0;}
/*=============================================
			What We Offers
=============================================*/
.tg-services{
	width:100%;
	float:left;
	margin:-15px 0;
}
.tg-service{
	width:100%;
	float:left;
	height:400px;
	padding:60px;
	margin:15px 0;
	text-align:center;
	border-radius:5px;
	border:1px solid #ddd;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.tg-service:hover{
	border-color:#999;
	-webkit-box-shadow: 0 0 15px 3px rgba(0,0,0,0.10);
	box-shadow: 0 0 15px 3px rgba(0,0,0,0.10);
}
.tg-servicesslider .owl-item{padding:0 15px;}
.tg-seviceicon{
	width:100px;
	height:100px;
	display:block;
	margin:0 0 30px;
	line-height:98px;
	text-align:center;
	border-radius:50%;
	border:1px solid #ddd;
}
.tg-seviceicon i{
	display:block;
	font-size:26px;
	line-height:inherit;
}
.tg-service:hover .tg-seviceicon {
	border-color:#999;
	-webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.10);
	box-shadow: 0 0 10px 2px rgba(0,0,0,0.10);
}
.tg-service .tg-description p{ margin:0;}
/*=============================================
			Video Why Choose Us?
=============================================*/
.tg-videosection,
.tg-imagesection{
	width:100%;
	float:left;
	overflow:hidden;
}
.tg-videosection figure,
.tg-imagesection figure{
	margin:0;
	width:100%;
	float:left;
	height:500px;
	overflow:hidden;
	position:relative;
}

.tg-imagesection figure{
	height: 400px;
}
 
.tg-videosection figure img,
.tg-imagesection figure img{
	z-index:1;
	max-width:none;
	width:100%;
	height:auto;
	opacity:0.60;
	display:block;
	position:relative;
}

.tg-imagesection figure img {
	opacity: 1;
}

.tg-videosection figure figcaption{
	top:0;
	left:0;
	z-index:2;
	width:100%;
	height:100%;
	position:absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.tg-btnplay{
	color:#fff;
	width:100px;
	height:100px;
	display:block;
	font-size:24px;
	line-height:96px;
	text-align:center;
	border-radius:50%;
	border:2px solid #fff;
}
.tg-btnplay:hover{ color:#fff;}
.tg-videosection figure figcaption h2{
	margin:0;
	color:#fff;
	font-size:24px;
	line-height:27px;
	padding:20px 50% 0;
}
/* =============================================
			Skills Start
============================================= */
.tg-whychooseus{
	max-width:570px;
	width:100%;
	float:left;
	text-align:left;
	padding:80px 0 80px 15px;
}
.tg-whychooseus .tg-sectionhead{ text-align:left;}
.tg-skillgroup{
	float:left;
	width:100%;
}
.tg-skill{
	float:left;
	width:100%;
	cursor: pointer;
}
.tg-skill + .tg-skill{margin:20px 0 0;}
.tg-skillholder h5,
.tg-skill h4{
	margin:0;
	font-size:14px;
	line-height:11px;
	padding:0 0 10px;
	text-transform: uppercase;
}
.tg-skillholder{
	float:left;
	width:100%;
	position:relative;
	border-top:1px solid #999;
}
.tg-skillbar{
	height:3px;
	float:left;
	transition:none;
	background:#999;
	position:relative;
}
.tg-skillholder h5{
	right: 0;
	bottom: 100%;
	position: absolute;
}
/* =============================================
			Professional Team
============================================= */
.tg-socialprofilelinks{
	width:100%;
	float:left;
	list-style:none;
	line-height:36px;
	text-align:center;
}
.tg-socialprofilelinks li{
	vertical-align:top;
	display:inline-block;
	list-style-type:none;
	line-height:inherit;
}
.tg-socialprofilelinks li a{
	color:#fff;
	width:40px;
	height:40px;
	display:block;
	overflow:hidden;
	border-radius:50%;
	border:2px solid #fff;
}
.tg-socialprofilelinks li a i{
	display:block;
	line-height:36px;
	text-align:center;
}
.tg-socialprofilelinks li a:hover{ background:#fff;}
.tg-teammembers{
	width:100%;
	float:left;
	margin:-15px 0;
}
.tg-teamslider.tg-teammembers{margin:0;}
.tg-member{
	width:100%;
	float:left;
	margin:15px 0;
	overflow:hidden;
	position:relative;
}
.tg-member.item{ margin:0;}
.tg-member figure{
	width:100%;
	float:left;
	margin:0;
	overflow:hidden;
	border-radius:2px;
	border:1px solid #ddd;
}
.tg-member figure a{ display:block;}
.tg-member figure img,
.tg-member figure a img{
	z-index:1;
	display:block;
	position:relative;
}
.tg-member figure figcaption{
	top:0;
	left:0;
	z-index:2;
	width:100%;
	height:100%;
	position:absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}
.tg-member:hover figure img,
.tg-member:hover figure a img{ opacity:0.40;}
.tg-member:hover figure figcaption{
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}
.tg-memberinfo{
	width:100%;
	float:left;
	padding:20px 0;
	position:relative;
}
.tg-memberinfo:before{
	bottom:0;
	left:0;
	width:30px;
	height:2px;
	content:'';
	background:#ddd;
	position:absolute;
}
.tg-membername{
	width:100%;
	float:left;
}
.tg-membername h4{
	font-size:18px;
	line-height:18px;
	margin:0 0 15px;
}
.tg-memberdesignation{
	width:100%;
	float:left;
}
.tg-memberdesignation{
	font-size:14px;
	line-height:14px;
}
.tg-memberdesignation a{ color:#515151;}
.tg-member:hover .tg-memberinfo:before{ width:100%;}
.tg-teamslider .owl-item{padding:0 15px 15px;}
.tg-teamslider .owl-controls{
	width:100%;
	float:left;
}
/* =============================================
			Statistics
============================================= */
.tg-statistics{
	width:100%;
	float:left;
	padding:80px 0;
	min-height:220px;
}
.tg-statistic{
	width: 33.33%;
	float: left;
	padding: 0 50px;
	position:relative;
}
.tg-icon{
	float:left;
	color:#fff;
	font-size:58px;
	line-height:58px;
	margin:0 15px 0 0;
}
.tg-namecount{
	overflow:hidden;
	padding: 0 0 0 15px;
	border-left: 2px solid #fff;
}
.tg-statistic h2{
	color:#fff;
	margin:0 0 10px;
	font-size: 40px;
	line-height: 30px;
}
.tg-statistic h2 span{ display:block;}
.tg-statistic h3{
	margin: 0;
	color:#fff;
	font-size: 18px;
	line-height: 18px;
}
/* =============================================
			Projects
============================================= */
.tg-themetabs{
	width:100%;
	float:left;
}
.tg-themetabnav{
	width:100%;
	float:left;
	padding:0 0 30px;
	text-transform:uppercase;
	font:400 12px/30px 'Montserrat', Arial, Helvetica, sans-serif
}
.tg-themetabnav ul{
	width:100%;
	float:left;
	list-style:none;
	text-align:center;
	line-height:inherit;
}
.tg-themetabnav ul li{
	vertical-align:top;
	display:inline-block;
	line-height:inherit;
	list-style-type:none;
}
.tg-themetabnav ul li a{
	display:block;
	color:#515151;
	padding:0 20px;
	line-height:30px;
	border-radius:2px;
	border:1px solid transparent;
}
.tg-themetabcontent{
	width:100%;
	float:left;
}
.tg-themetabcontent > div{
	width:100%;
	float:left;
	min-height:266px;
	position:relative;
}
.tg-projects{
	width:100%;
	float:left;
	min-height:266px;
}
.tg-projects .owl-wrapper-outer{ float:left;}
.tg-project{
	width:100%;
	float:left;
}
.tg-project figure{
	width:100%;
	float:left;
	z-index:1;
	margin:0;
	position:relative;
	border-radius:3px;
	border:1px solid #ddd;
}
.tg-project figure img{
	display:block;
	width:100%;
	height:auto;
}
.tg-project:hover figure img{opacity:0.40;}
.tg-project figure figcaption{
	bottom:0;
	left:0;
	width:100%;
	height:auto;
	padding:7px 10px;
	position:absolute;
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
	transform: translateY(100px);
}
.tg-project:hover figure figcaption{
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}
.tg-project figure figcaption h4{
	margin:0;
	font-size:18px;
	line-height:21px;
}
.tg-project figure figcaption h4 a{ color:#fff;}
.tg-projects .owl-item{padding:0 5px 15px;}
.tg-projects .item{
	width:100%;
	float:left;
	padding:0;
}
.tg-projects .clickable{
	bottom:0;
	left:0;
	width:100%;
	position:absolute;
}
/* =============================================
			Projects
============================================= */
.tg-bgimage{
	-webkit-background-size:100% auto;
	-moz-background-size:100% auto;
	-ms-background-size:100% auto;
	-o-background-size:100% auto;
	background-size:100% auto;
	background-repeat:repeat-y;
	background-image:url(../images/bg-01.png);
}
.tg-requestcallback figure{
	margin:0;
	width:100%;
	float:left;
}
.tg-requestcallback figure img{
	max-width:none;
	margin:-40px 0 0 -230px;
}
.tg-contentbox{
	width:100%;
	float:left;
	padding:80px 0;
}
.tg-contentbox .tg-sectionhead{ text-align:left;}
.tg-formtheme{
	width:100%;
	float:left;
}
.tg-formtheme fieldset{
	width:100%;
	float:left;
	padding:0;
	margin:0;
	border:0;
}
.tg-formtheme fieldset > div > div:nth-last-child(1) .form-group,
.tg-formtheme fieldset > div > div:nth-last-child(2) .form-group{
	margin:0;
	width:100%;
	float:left;
}
.tg-formtheme fieldset .tg-btn{
	padding:0 33px;
	height:50px;
	line-height:46px;
}
.tg-formtheme fieldset .tg-btn span:before{ line-height:48px;}
.tg-contentbox .tg-sectionhead{ padding:0 0 20px;}
.tg-contentbox .tg-sectiontitle{ padding:0 0 30px;}
/* =============================================
			Testimonials
============================================= */
.tg-clientfeedback{
	width:100%;
	float:left;
}
.tg-testimonial{
	width:100%;
	float:left;
	margin:38px 0 57px;
	padding:79px 30px;
	min-height:200px;
	position:relative;
	border-radius:5px;
	border:1px solid #ddd;
}
.tg-testimonial:before{
	top:-38px;
	left:20px;
	width:75px;
	height:75px;
	color:#ddd;
	content:'\f10d';
	background:#fff;
	position:absolute;
	font-size:60px;
	line-height:70px;
	text-align:center;
	font-family:'FontAwesome';
}
.tg-testimonial blockquote{
	width:100%;
	float:left;
	margin:0;
	padding:0;
	border:0;
	font-size:14px;
	line-height:20px;
}
.tg-testimonial blockquote q{
	width:100%;
	float:left;
	font-style:normal;
}
.tg-clientinfo{
	bottom:-42px;
	right:20px;
	width:250px;
	padding:10px;
	background:#fff;
	border-radius:3px;
	position:absolute;
	border:1px solid #ddd;
}
.tg-clientinfo figure{
	float:left;
	overflow:hidden;
	border-radius:50%;
	margin:0 10px 0 0;
	border:1px solid #ddd;
}
.tg-clientinfo figure a,
.tg-clientinfo figure a img{ display:block;}
.tg-namecountery{
	overflow:hidden;
	padding:12px 0;
}
.tg-namecountery h4{
	font-size:16px;
	line-height:13px;
	margin:0 0 5px;
}
.tg-namecountery span{display:block;}
.tg-testimonial:hover,
.tg-testimonial:hover .tg-clientinfo{border-color:#515151;}
.tg-testimonialsslidertwo .owl-item,
.tg-testimonialsslider .owl-item{padding:0 15px;}
/* =============================================
			Get Advice
============================================= */
.tg-getadvice{
	width:100%;
	float:left;
	color:#fff;
}
.tg-getadvice .tg-btn{
	float:right;
	color:#fff;
	border-color:#fff;
}
.tg-getadvice p{
	margin:17px 0;
	font-size:24px;
	line-height:27px;
}
.tg-getadvice p strong{
	color:#fff;
	text-transform:uppercase;
}
/* =============================================
			Trusted By Many
============================================= */
.tg-brands{
	width:100%;
	float:left;
}
.tg-brand{
	width:100%;
	float:left;
	height:150px;
	margin:0;
	padding:10px;
	border-radius:3px;
	border:1px solid #ddd;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.tg-brand a,
.tg-brand a img{ display:block;}
.tg-brand:hover{ border-color:#515151;}
.tg-brandsslider .owl-item{ padding:0 15px 15px;}
/* =============================================
			Footer
============================================= */
.tg-footer{overflow:hidden;}
.tg-footertopbar{
	width:100%;
	float:left;
	z-index:2;
	padding:50px 0;
	position:relative;
}
.tg-formtitle{
	width:100%;
	float:left;
	padding:0 20px 0 0;
	text-transform:uppercase;
	font:24px/24px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-signuparea,
.tg-formsignup{
	width:100%;
	float:left;
}
.tg-formsignup fieldset{
	width:100%;
	float:left;
	margin:0;
	padding:0;
	border:0;
}
.tg-formsignup fieldset .form-group{ margin:0;}
.tg-formsignup fieldset .form-group .tg-btn{
	height:50px;
	padding:0 20px;
	line-height:46px;
}
.tg-formsignup fieldset .form-group .tg-btn span{ padding:0 9px;}
.tg-formsignup fieldset .form-group .tg-btn span:before{ line-height:46px;}
.tg-footermiddlearea{
	width:100%;
	float:left;
	z-index:1;
	position:relative;
}
.tg-footerwidgets{
	width:100%;
	float:left;
}
.tg-footerwidgets > div[class*="col-"]:first-child{ position:relative;}
.tg-footerwidgets > div[class*="col-"]:first-child:before{
	top:0;
	content:'';
	right:100%;
	width:9999px;
	height:100%;
	position:absolute;
}
.tg-widget{
	width:100%;
	float:left;
	padding:80px 0;
	position:relative;
}
.tg-widget + .tg-widget{ margin:60px 0 0;}
.tg-widget + .tg-widget:before{
	top:-30px;
	left:0;
	width:100%;
	height:1px;
	content:'';
	background:#ddd;
	position:absolute;
}
.tg-footerwidgets .tg-widget{ margin:0;}
.tg-footerwidgets .tg-widget.tg-widgetinfo{ padding:80px 15px 133px 0;}
.tg-footerlocation-map{
	width:100%;
	float:left;
	height:130px;
	margin:0 0 20px;
	border-radius:3px;
}
.tg-widget.tg-widgetinfo h4{
	width:100%;
	float:left;
	color:#fff;
	margin:0 0 20px;
	font-size:18px;
	line-height:14px;
}
.tg-contactinfo{
	color:#fff;
	width:100%;
	float:left;
	list-style:none;
	line-height: 17px;
}
.tg-widget .tg-contactinfo li{ border:0;}
.tg-contactinfo li{
	width:100%;
	float:left;
	list-style-type:none;
	line-height: inherit;
}
.tg-widget .tg-contactinfo li:first-child{ padding:0;}
.tg-widget .tg-contactinfo li + li{ padding:20px 0 0;}
.tg-contactinfo li i,
.tg-contactinfo li address,
.tg-contactinfo li span,
.tg-contactinfo li span a{
	display:inline-block;
	vertical-align:top;
	margin: 0;
	font-size: 14px;
	line-height: inherit;
	font-style: normal;
	text-transform: none;
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
.tg-contactinfo li i{
	padding: 0 15px 0 0;
	font-family: 'FontAwesome';
}
.tg-contactinfo li address{ width: 82%;}
.tg-widgettitle{
	width:100%;
	float:left;
	margin:0 0 20px;
	padding:0 0 20px;
	border-bottom:2px solid #ddd;
}
.tg-widgettitle h3{
	font-size:18px;
	line-height:14px;
	color:#fff;
	margin:0;
}
.tg-widget ul{
	width:100%;
	float:left;
	list-style:none;
	line-height:17px;
}
.tg-widget ul li{
	width:100%;
	float:left;
	padding:15px 0;
	line-height:inherit;
	list-style-type:none
}
.tg-widget ul li a{ color:#515151;}
.tg-widget ul li + li{ border-top:1px solid #ddd;}
.tg-footerwidgets .tg-widget ul li + li{ border-color:#657584;}
.tg-widgetusefulllinks ul li a{
	display:block;
	position:relative;
	padding:0 0 0 20px;
}
.tg-widgetusefulllinks ul li a:after{
	top:0;
	left:0;
	content:'\f105';
	position:absolute;
	font-family: 'FontAwesome';
}
.tg-footerwidgets .tg-widget ul li a:hover,
.tg-footerwidgets .tg-widget ul li a{ color:#fff;}
.tg-widgetusefulllinks ul li:first-child,
.tg-widgetrecentnews ul li:first-child{ padding-top:10px;}
.tg-widgetusefulllinks ul li,
.tg-widgetrecentnews ul li{ padding:20px 0;}
.tg-widgetrecentnews ul li time{
	width:41px;
	height:41px;
	float:left;
	padding:7px 0;
	font-size:12px;
	line-height:15px;
	background:#fff;
	border-radius:3px;
	margin:0 10px 0 0;
	text-align:center;
}
.tg-widgetrecentnews ul li time a{ display:block;}
.tg-widgetrecentnews ul li time a span{
	display:block;
	font:18px/14px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-widget ul li .tg-postmetadata{ padding:0;}
.tg-widgetrecentnews ul li .tg-posdata{ overflow:hidden;}
.tg-widgetrecentnews ul li .tg-posdata h4{
	font-size:14px;
	line-height:17px;
	text-transform:none;
	font:13px/17px 'Open Sans', Arial, Helvetica, sans-serif;
}
.tg-postmetadata{
	margin:0;
	width:100%;
	float:left;
	font-size:13px;
	list-style:none;
	line-height:16px;
	padding:0 0 10px;
}
.tg-postmetadata li{
	float:left;
	padding:0 10px;
	position:relative;
	line-height:inherit;
	list-style-type:none;
}
.tg-postmetadata li:first-child{ padding-left:0;}
.tg-postmetadata li + li:before{
	top:0;
	left:-2px;
	content:'/';
	position:absolute;
}
.tg-postmetadata li a{
	display:block;
	color:#515151;
}
.tg-widget ul li .tg-postmetadata li{ padding:0;}
.tg-widgetflicker ul{
	padding:5px 0 0;
	width:auto;
	float:none;
	overflow:hidden;
	clear:both;
	margin:0 -5px;
}
.tg-widget.tg-widgetflicker ul li{
	float:left;
	width:33.33%;
	padding:5px;
	border:0;
}
.tg-widget.tg-widgetflicker ul li:first-child{width:66.65%;}
.tg-widgetflicker ul li figure{
	width:100%;
	float:left;
	margin:0;
	overflow:hidden;
	border-radius:2px;
	border:1px solid #ddd;
}
.tg-widgetflicker ul li figure a{ display:block;}
.tg-widgetflicker ul li figure a img{
	display:block;
	width:100%;
	height:auto;
}
.tg-footerbottombar{
	width:100%;
	float:left;
	padding:30px 0;
	border-top:2px solid #fff;
}
.tg-copyright{
	float:left;
	color:#fff;
	padding:8px 0;
}
body.tg-fixednav .tg-footerbottombar .tg-logo,
.tg-footerbottombar .tg-logo{
	width:231px;
	display:block;
	margin:0 auto;
	padding:0;
	float:none;
}
.tg-socialicons{
	float:right;
	padding:5px 0;
}
/* =============================================
			Header Version Two
============================================= */
.tg-header.tg-headerVtwo{margin:0 0 -172px;}
.tg-headerVtwo .tg-logo {margin: 29px 0;}
.tg-headerVtwo .tg-nav{ padding:34px 0 0;}
.tg-headerVtwo .tg-navigation > ul > li{ padding:0 0 34px;}
/* =============================================
			Home Version Three
============================================= */
.tg-boxedversion{
	max-width:1300px;
	clear:both;
	margin:0 auto;
	float:none;
	box-shadow:0 0 15px 5px rgba(0, 0, 0, 0.1);
}
.tg-boxedversion:after{
	clear:both;
	content:'';
	display:block;
}
.tg-boxedversion .tg-header{
	margin:0;
	box-shadow:none;
}
.tg-boxedversion .tg-topbar{
	color:#fff;
	background:#515151;
}
.tg-boxedversion .tg-topbar a{ color:#fff;}
.tg-boxedversion .tg-addnav{
	background:none;
	padding:0;
}
.tg-boxedversion .tg-addnav:after,
.tg-boxedversion .tg-addnav:before{ display:none;}
.tg-topmiddlebar{
	width:100%;
	float:left;
	padding:35px 0;
}
.tg-topmiddlebar .tg-logo{
	width:275px;
	float:none;
	display:block;
	margin:6px auto;
}
.tg-servicehours{
	width:300px;
	float:left;
	padding:0 0 0 30px;
}
.tg-servicehours > span{
	display:block;
	text-transform:uppercase;
	font:16px/19px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-servicehours .owl-controls{
	top:0;
	left:-20px;
	padding:0;
	position:absolute;
}
.tg-servicehours .owl-controls .owl-buttons div{
	margin:0;
	padding:0;
	font-size:18px;
	color:#515151;
	background:none;
	position:absolute;
	top:0;
	left:0;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.tg-servicehours .owl-controls .owl-buttons div.owl-prev{ margin:-20px 0 0;}
.tg-servicehours .owl-controls .owl-buttons div.owl-next{}
.tg-topmiddlebar .tg-btn{
	float:right;
	line-height:50px;
}
.tg-boxedversion .tg-navigationarea{
	border:0;
	margin:0 0 1px;
	border-radius:5px;
}
body.tg-fixednav .tg-boxedversion .tg-navigationarea{
	border-radius:0;
	margin:0;
}
body.tg-fixednav .tg-boxedversion .tg-navigation > ul > li,
body.tg-fixednav .tg-boxedversion .tg-nav,
.tg-boxedversion .tg-navigation > ul > li,
.tg-boxedversion .tg-nav{ padding:0;}
body.tg-fixednav .tg-boxedversion .tg-nav,
.tg-boxedversion .tg-nav{ float:left;}
body.tg-fixednav .tg-boxedversion .tg-navigation > ul > li > a,
.tg-boxedversion .tg-navigation > ul > li > a{
	color:#fff;
	border:0;
	line-height:50px;
}
.tg-boxedversion .mega-menu{
	left: -80px !important;
    width: 1170px !important;
}
.tg-searchdropdown{
	float: right;
	position: relative;
}
.tg-btnsearch{
	color:#fff;
	float:right;
	width:50px;
	height:50px;
	display:block;
	line-height:50px;
	text-align:center;
	border-radius:0 5px 5px 0;
}
.tg-btnsearch:focus,
.tg-btnsearch:hover{ color:#fff;}
.tg-dropdownsearch{
	top: 200%;
	right: 2px;
	left: auto;
	float: none;
	margin: 0;
	border: 0;
	opacity: 0;
	width: 300px;
	padding: 10px;
	border-radius: 0;
	box-shadow: none;
	position: absolute;
	visibility: hidden;
	display: block !important;
}
.tg-dropdownsearch form{
	width: 100%;
	float: left;
}
.tg-dropdownsearch form fieldset{
	margin: 0;
	padding: 0;
	border: 0;
	width: 100%;
	float: left;
}
.tg-searchdropdown.open .tg-dropdownsearch{
	top: 52px;
	opacity: 1;
	visibility: visible;
}
.tg-boxedversion .tg-homeslider figure figcaption{
	padding:0 100px;
	text-align:left;
	align-items:flex-end;
}
.tg-homeslider figure figcaption h2 {
	font-size: 70px;
	line-height: 70px;
	padding:0 0 50px;
}
.tg-homeslider figure figcaption .tg-svginject{
	width:120px;
	right:50px;
}
.tg-boxedversion .tg-homeslider figure figcaption .tg-description{ padding:0;}
.tg-boxedversion .tg-homeslider .owl-controls .owl-buttons div{
	width:50px;
	height:50px;
	border-width:1px;
}
.tg-boxedversion .tg-homeslider .owl-controls .owl-buttons div > i{
	font-size:20px;
	line-height:48px;
}
.tg-serviceimg{
	width:100%;
	float:left;
	margin:0;
}
.tg-paddingbottomzero{ padding-bottom:0;}
.tg-serviceimg img{ display:block;}
.tg-servicestyletwo .tg-box{
	width:100%;
	float:left;
	margin:15px 0;
	position:relative;
}
.tg-servicestyletwo > div:nth-child(odd) .tg-box{padding:0 35px 0 0;}
.tg-servicestyletwo > div:nth-child(even) .tg-box{padding:0 0 0 35px;}
.tg-servicestyletwo .tg-service{
	margin:0;
	height:auto;
}
.tg-servicestyletwo .tg-seviceicon{
	margin:0;
	width:70px;
	height:70px;
	line-height:70px;
	background:#fff;
	border-radius:3px;
	position:absolute;
}
.tg-servicestyletwo .tg-service:hover .tg-seviceicon i{ color:#fff;}
.tg-servicestyletwo > div:nth-child(odd) .tg-seviceicon{
	top:0;
	right:0;
}
.tg-servicestyletwo > div:nth-child(even) .tg-seviceicon{
	left:0;
	bottom:0;
}
.tg-servicestyletwo .tg-heading{padding-top:0;}
.tg-servicestyletwo .tg-heading:before{ display:none;}
.tg-boxedversion .tg-videosection figure img{
	height:500px;
	width:auto;
}
.tg-testimonialsstyletwo{text-align:center;}
.tg-testimonialsstyletwo .tg-testimonial{ padding:150px 30px 120px 30px;}
.tg-testimonial .tg-stars{
	top:-12px;
	left:50%;
	width:148px;
	background:#fff;
	position:absolute;
	margin:0 0 0 -74px;
}
.tg-testimonial .tg-stars,
.tg-testimonial .tg-stars span{
	padding:0 10px;
	line-height:24px;
	height:24px;
}
.tg-testimonial .tg-stars span{
	width:100%;
	padding:0;
}
.tg-testimonial .tg-stars span:after,
.tg-testimonial .tg-stars:before{
	font-size:24px;
	line-height:24px;
	height:24px;
}
.tg-testimonial .tg-stars:before{ left:10px;}
.tg-testimonialsstyletwo .tg-testimonial:before{
	top:80px;
	left:50%;
	width:60px;
	height:60px;
	line-height:60px;
	margin:0 0 0 -30px;
}
.tg-testimonialsstyletwo .tg-clientinfo{
	right:50%;
	margin:0 -125px 0 0;
}
/* ==========================================================================================
										Pages
========================================================================================== */
/* =============================================
			Inner Banner
============================================= */
.tg-innerbanner{}
.tg-parallaximg{
	width:100%;
	float:left;
	height:280px;
	overflow:hidden;
	position:relative;
}
.tg-innerbannercontent{
	width:100%;
	float:left;
	padding:30px 0 33px;
	border-bottom:2px solid #ddd;
}
.tg-pagetitle{float:left;}
.tg-pagetitle h1{
	margin:0;
	font-size:18px;
	line-height:18px;
}
/* =============================================
			About Us
============================================= */
.tg-whatwedo{
	width:100%;
	float:left;
	text-align:left;
}
.tg-whatwedo .tg-sectionhead{
	text-align:left;
	padding:0 0 15px;
}
.tg-whatwedo .tg-sectiontitle{padding:0 0 30px;}
.tg-whatwedo ul{
	width:100%;
	float:left;
	list-style:none;
}
.tg-whatwedo ul li{
	width:100%;
	float:left;
	list-style-type:none;
}
.tg-whatwedo ul li + li{
	margin:30px 0 0;
	padding:30px 0 0;
	border-top:1px solid #ddd;
}
.tg-whatwedoicon{
	width:40px;
	height:40px;
	float:left;
	background:#fff;
	border-radius:50%;
	line-height:38px;
	margin:0 20px 0 0;
	text-align:center;
	border:1px solid #ddd;
}
.tg-whatwedoicon i{
	display:block;
	line-height:inherit;
}
.tg-whatwedocontent{
	padding:10px 0 0;
	overflow:hidden;
}
.tg-whatwedocontent h4{
	font-size:18px;
	line-height:18px;
	margin:0 0 20px;
}
.tg-whatwedocontent .tg-description{}
.tg-whatwedocontent .tg-description p{ margin:0;}
.tg-whatwedo ul li:hover .tg-whatwedoicon i{ color:#fff;}
.tg-whatwedoimages{
	width:100%;
	float:left;
}
.tg-whatwedoimages figure{
	width:49%;
	margin:0;
	float:left;
	border:1px solid #ddd;
}
.tg-whatwedoimages figure + figure{ float:right;}
.tg-whatwedoimages figure:last-child{
	width:100%;
	margin:10px 0 0;
}
.tg-whatwedoimages figure img{ display:block;}
.tg-wearehiring{
	width:100%;
	float:left;
	overflow:hidden;
	border-radius:3px;
	border:1px solid #ddd;
}
.tg-wearehiring figure{
	margin:0;
	width:100%;
	float:left;
}
.tg-wearehiring figure img{
	display:block;
	width:100%;
	height:auto;
}
.tg-wearehiringcontent{
	width:100%;
	float:left;
	padding:32px 30px;
}
.tg-wearehiringcontent h5{
	margin:0 0 25px;
	text-align:center;
	font-size:18px;
	line-height:18px;
}
.tg-wearehiringcontent h5 span{
	display:block;
	font-size:30px;
	line-height:30px;
}
.tg-wearehiringcontent .tg-btn{
	width:100%;
	height:50px;
	line-height:46px;
	padding:0 20px;
	overflow:hidden;
}
.tg-wearehiringcontent .tg-btn span:before{ line-height:46px;}
/* =============================================
			Project Page
============================================= */
.tg-pagecontent{
	width:100%;
	float:left;
	padding:80px 0;
}
.tg-filter-masonry.tg-projects{ padding:0 10px;}
.tg-project{
	width:19.6%;
	float:left;
	padding:5px;
}
.tg-navfilterbale{
	width:100%;
	float:left;
	list-style:none;
	padding:0 0 30px;
	text-align:center;
	text-transform:uppercase;
	font:400 12px/30px 'Montserrat', Arial, Helvetica, sans-serif
}
.tg-navfilterbale li{
	vertical-align:top;
	display:inline-block;
	line-height:inherit;
	list-style-type:none;
}
.tg-navfilterbale li a{
	display:block;
	color:#515151;
	padding:0 20px;
	line-height:30px;
	border-radius:2px;
	border:1px solid transparent;
}
/* =============================================
			Detail Page Style
============================================= */
.tg-detailpage{
	width:100%;
	float:left;
}
.tg-postbuttons{
	width:100%;
	float:left;
	padding:0 0 30px;
}
.tg-postbuttons .tg-btn{
	margin:0;
	height:50px;
	color:#515151;
	line-height:46px;
	border-color:#515151;
}
.tg-postbuttons .tg-btn span:before{ line-height:46px;}
.tg-postbuttons .tg-btn.tg-prevpost{float:left;}
.tg-postbuttons .tg-btn.tg-nextpost{float:right;}
.tg-postbuttons .tg-btn:hover span{ color:#fff;}
.tg-images{
	clear:both;
	margin:-5px;
	padding:0 0 30px;
	overflow:hidden;
}
.tg-images figure{
	margin:0;
	padding:5px;
}
.tg-images figure.tg-smallimg{
	width:33%;
	float:left;
}
.tg-images figure.tg-largeimg{
	width:67%;
	float:right;
}
.tg-images figure .tg-box{
	width:100%;
	float:left;
	z-index:1;
	overflow:hidden;
	border-radius:3px;
	position:relative;
}
.tg-images figure img{
	width:100%;
	height:auto;
	display:block;
}
.tg-images .tg-box:hover img{ opacity:0.20;}
.tg-images figcaption{
	top:-100%;
	left:0;
	z-index:2;
	width:100%;
	height:100%;
	position:absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.tg-btnplusicon{
	color:#fff;
	width:40px;
	height:40px;
	display:block;
	font-size:16px;
	line-height:36px;
	border-radius:50%;
	text-align:center;
	border:2px solid #fff;
}
.tg-btnplusicon:hover{ color:#fff;}
.tg-images .tg-box:hover figcaption{ top:0;}
.tg-detailpage .tg-description{
	padding:0 0 10px;
	border-bottom:1px solid #ddd;
}
.tg-posttitle{
	width:100%;
	float:left;
	padding:0 0 15px;
}
.tg-posttitle h2{
	margin:0;
	font-size:18px;
	line-height:18px;
}
.tg-videobox{
	width:370px;
	height:185px;
	overflow:hidden;
	border-radius:5px;
	position:relative;
	border:1px solid #ddd;
}
.tg-videobox iframe{
	top:0;
	left:0;
	width:100%;
	height:100%;
	position:absolute;
}
.tg-alignright{
	float:right;
	margin:0 0 30px 30px;
}
.tg-alignleft{
	float:left;
	margin:0 30px 30px 0;
}
.tg-alignright,
.tg-alignleft{
	overflow:hidden;
	border-radius:5px;
	border:1px solid #dddd;
}
.tg-teaminvolved{
	width:100%;
	float:left;
	padding:30px 0;
}
.tg-teaminvolved > h3{margin:0 0 15px;}
.tg-grapharea{
	width:100%;
	float:left;
	padding:30px 0;
	border-top:1px solid #ddd;
	border-bottom:1px solid #ddd;
}
.tg-testimonialarea > h3,
.tg-grapharea h3{
	margin:0 0 20px;
	font-size:18px;
	line-height:18px;
}
.tg-performancechart {
	width: 100%;
	float: left;
	height: 460px;
	padding: 30px;
	border-radius:5px;
	border:1px solid #ddd;
}
.tg-performancechart > div > div {}
.canvasjs-chart-credit{ display:none;}
.tg-testimonialarea{
	width:100%;
	float:left;
	padding:30px 0 0;
}
.tg-testimonialarea h3{ margin:0;}
.tg-testimonialarea .tg-testimonial{ margin:20px 0 57px;}
.tg-testimonialarea .tg-testimonialsstyletwo .tg-testimonial {padding:120px 15px 100px 15px;}
.tg-testimonialarea .tg-testimonial .tg-stars{
	top:-8px;
	width:110px;
	margin:0 0 0 -55px;
}
.tg-testimonialarea .tg-testimonial .tg-stars,
.tg-testimonialarea .tg-testimonial .tg-stars span{height:16px;}
.tg-testimonialarea .tg-testimonial .tg-stars span:after,
.tg-testimonialarea .tg-testimonial .tg-stars:before {
	font-size: 16px;
	line-height: 16px;
	height: 16px;
}
.tg-testimonialarea .tg-testimonialsstyletwo .tg-testimonial:before{
	top:60px;
	height:50px;
	font-size:50px;
	line-height:50px;
}
.tg-testimonialarea .tg-clientinfo{
	width:230px;
	text-align:left;
	margin:0 -115px 0 0;
}
.tg-siderbar{
	width:100%;
	float:left;
}
.tg-siderbar .tg-widget{
	padding:0;
	border-radius:4px;
	border:1px solid #ddd;
}
.tg-siderbar .tg-widgettitle{
	width:100%;
	float:left;
	margin:0;
	border:0;
	padding:20px;
	background:#515151;
	border-radius:4px 4px 0 0;
}
.tg-siderbar .tg-widgettitle h3{
	color:#fff;
	font-size:16px;
	line-height:16px;
}
.tg-widgetcontent{
	width:100%;
	float:left;
	padding:0 20px;
	color:#515151;
}
.tg-widgetprojectdescription ul li span:nth-child(odd){float:left;}
.tg-widgetprojectdescription ul li span:nth-child(even){float:right;}
.tg-widgetprojectdescription ul li span i,
.tg-widgetprojectdescription ul li span em{
	display:inline-block;
	vertical-align:middle;
	font-style:normal;
}
.tg-widgetprojectdescription ul li span:nth-child(odd) i{
	width:25px;
	padding:0 10px 0 0;
}
.tg-widgetprojectdescription .tg-socialprofilelinks {
	margin:0;
	line-height:17px;
}
.tg-widgetprojectdescription .tg-socialprofilelinks li{
	width:auto;
	float:left;
	border:0;
	padding:0;
	padding:0 16px;
	color:#515151;
	position:relative;
	line-height:inherit;
}
.tg-widgetprojectdescription .tg-socialprofilelinks li:last-child{ padding-right:0;}
.tg-widgetprojectdescription .tg-socialprofilelinks li + li:before{
	top:0;
	left:0;
	content:'-';
	position:absolute;
}
.tg-widgetprojectdescription .tg-socialprofilelinks li a{
	width:auto;
	height:auto;
	border:0;
	color:#515151;
	border-radius:0;
}
.tg-widgetprojectdescription .tg-socialprofilelinks li a i{ line-height:inherit;}
.tg-companylocationmap{
	width:100%;
	height:130px;
	margin:20px 0;
}
.tg-widgetcontent .tg-contactinfo{
	color:#515151;
	margin:0 0 20px;
}
.tg-widgetcontent .tg-contactinfo li address {width: 84%;}
.tg-widgetcontent .tg-contactinfo a{color:#515151;}
.tg-brochure{
	width:48%;
	height:110px;
	float:left;
	padding:10px;
	margin:20px 0;
	overflow:hidden;
	position:relative;
	border-radius:4px;
	background:#f7f7f7;
	border:1px solid #ddd;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.tg-brochure + .tg-brochure{ float:right;}
.tg-btndownload{
	color:#fff;
	max-width:84px;
	width:100%;
	height:28px;
	text-align:center;
	border-radius:4px;
	text-transform:uppercase;
	font: 10px/28px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-btndownload:hover{color:#fff;}
.tg-brochure i.fa{
	font-size:26px;
	line-height:26px;
}
.tg-brochure .tg-btndownload{
	position:absolute;
	left:50%;
	bottom:-110%;
	margin:15px 0 0 -42px;
}
.tg-brochure:hover{
	background:#fff;
	border-color:#999;
	padding:10px 10px 50px 10px;
}
.tg-brochure:hover .tg-btndownload{bottom:10px}
.tg-themeform{
	width:100%;
	float:left;
}
.tg-themeform fieldset{
	width:100%;
	float:left;
	margin:0;
	padding:0;
	border:0;
}
.tg-themeform fieldset .form-group{
	width:100%;
	float:left;
}
.tg-widgetcontent .tg-themeform{ padding:20px 0;}
.tg-widgetcontent .tg-themeform fieldset .tg-btn{
	width:100%;
	height:50px;
	line-height:50px;
}
.tg-widgetcontent .tg-themeform fieldset .tg-btn span:before{ line-height:46px;}
/* =============================================
			Event Grid Page
============================================= */
.tg-events{
	width:100%;
	float:left;
	margin:-15px 0;
	padding:0 0 30px;
}
.tg-event{
	width:100%;
	float:left;
	margin:15px 0;
	overflow:hidden;
	position:relative;
}
.tg-event figure{
	margin:0;
	width:100%;
	float:left;
	overflow:hidden;
	border-radius:5px;
	position:relative;
	border:1px solid #ddd;
}
.tg-event figure a{
	z-index:1;
	display:block;
	position:relative;
}
.tg-event figure a img{
	width:100%;
	height:auto;
	display:block;
}
.tg-event figure figcaption{
	left:0;
	bottom:-110%;
	z-index:2;
	width:100%;
	padding:20px;
	position:absolute;
}
.tg-eventcontent{
	width:100%;
	float:left;
	position:relative;
	padding:20px 0 25px;
}
.tg-borderstyle{ position:relative;}
.tg-borderstyle:before {
	bottom: 0;
	left: 0;
	width: 30px;
	height: 2px;
	content: '';
	background: #ddd;
	position: absolute;
}
.tg-eventtitle{
	width:100%;
	float:left;
	padding:0 0 15px;
}
.tg-eventtitle h2{
	margin:0;
	font-size:18px;
	line-height:18px;
}
.tg-eventtitle h2 a{
	display:block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.tg-eventcontent .tg-description p{ margin:0;}
.tg-event:hover .tg-borderstyle:before{ width:100%;}
.tg-eventmetadata{
	width:100%;
	float:left;
	list-style:none;
	background:#fff;
	border-radius:5px;
	text-align:center;
	text-transform:uppercase;
	font:12px/30px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-eventmetadata li{
	padding:0 9px;
	display:inline-block;
	vertical-align:top;
	position:relative;
	line-height:inherit;
	list-style-type:none;
}
.tg-eventmetadata li + li:before{
	top:0;
	left:-3px;
	content:'/';
	position:absolute;
}
.tg-event:hover figure a img{ opacity:0.20;}
.tg-event:hover figcaption{bottom:0;}
/* =============================================
			Event Detail
============================================= */
.tg-eventinfoimage{
	width:100%;
	float:left;
	overflow:hidden;
	margin:0 0 30px;
	border-radius:5px;
	border:1px solid #ddd;
}
.tg-eventinfo{
	width:100%;
	float:left;
	padding:24px;
	min-height:377px;
}
.tg-eventinfo h3{
	margin:0 0 20px;
	font-size:16px;
	line-height:16px;
}
.tg-eventinfo .tg-contactinfo a,
.tg-eventinfo .tg-contactinfo{ color:#515151;}
.tg-eventinfo .tg-contactinfo li + li{ padding:10px 0 0;}
.tg-eventinfo .tg-contactinfo li address {width: 90%;}
.tg-commentformarea{
	width:100%;
	float:left;
	padding:80px 0 0;
}
.tg-formcomment{
	width:100%;
	float:left;
}
.tg-formcomment fieldset{
	width:100%;
	float:left;
	margin:0;
	padding:0;
	border:0;
}
.tg-formcomment fieldset .form-group{
	width:100%;
	float:left;
}
.tg-formcomment fieldset .form-group textarea{
	height:160px;
	resize:none;
}
.tg-formcomment fieldset .tg-btn{
	height:50px;
	line-height:46px;
}
.tg-formcomment fieldset .tg-btn span:before{ line-height:46px;}
.tg-siderbar .tg-widgetsearch{ border:0;}
.tg-widgetsearch form{
	width:100%;
	float:left;
}
.tg-widgetsearch form fieldset{
	width:100%;
	float:left;
	margin:0;
	padding:0;
	border:0;
	position:relative;
}
.tg-widgetsearch form fieldset button{
	top:0;
	right:0;
	z-index:2;
	color:#999;
	width:50px;
	height:50px;
	background:none;
	line-height:50px;
	position:absolute;
	text-align:center;
}
.tg-widgetsearch form fieldset button i{
	display:block;
	line-height:50px;
}
.tg-widgetsimilar ul li h4{
	margin:0 0 10px;
	font-size:14px;
	line-height:17px;
}
.tg-widgetsimilar ul li time{
	display:block;
	font-size:13px;
	line-height:16px;
}
.tg-eventimg{
	margin:0;
	width:100%;
	float:left;
	border:0;
	border-left:1px solid #ddd;
}
.tg-eventimg a{ display:block;}
.tg-eventimg a img{
	width:100%;
	height:auto;
	display:block;
}
/* =============================================
			News Grid
============================================= */
.tg-newsposts{
	width:100%;
	float:left;
	margin:-15px 0;
	padding:0 0 30px;
}
.tg-news{
	width:100%;
	float:left;
	margin:15px 0;
	overflow:hidden;
	position:relative;
}
.tg-newsimg,
.tg-news figure{
	margin:0;
	width:100%;
	float:left;
	overflow:hidden;
	border-radius:5px;
	position:relative;
	border:1px solid #ddd;
}
.tg-newsimg img,
.tg-news figure img{
	z-index:1;
	width:100%;
	height:auto;
	display:block;
	position:relative;
}
.tg-news figure figcaption{
	top:0;
	left:0;
	z-index:2;
	width:100%;
	height:100%;
	padding:20px;
	position:absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-o-transform: translateY(100%);
	transform: translateY(100%);
}
.tg-newscontent{
	width:100%;
	float:left;
	position:relative;
	padding:20px 0 25px;
}
.tg-newstitle{
	width:100%;
	float:left;
	padding:0 0 15px;
}
.tg-newstitle h2{
	margin:0;
	font-size:18px;
	line-height:18px;
}
.tg-newstitle h2 a{
	display:block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.tg-newscontent .tg-description p{ margin:0;}
.tg-news:hover .tg-borderstyle:before{ width:100%;}
.tg-newsmetadata{
	width:100%;
	float:left;
	padding:0 0 15px;
	list-style:none;
	text-transform:uppercase;
	font:12px/15px 'Montserrat', Arial, Helvetica, sans-serif;
}
.tg-newsmetadata li{
	float:left;
	padding:0 9px;
	position:relative;
	line-height:inherit;
	list-style-type:none;
}
.tg-newsmetadata li + li:before{
	top:0;
	left:-3px;
	content:'/';
	position:absolute;
}
.tg-newsmetadata li:first-child{ padding-left:0;}
.tg-newsmetadata li a{ color:#515151;}
.tg-news:hover figure img{ opacity:0.20;}
.tg-news:hover figcaption{
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}
/* =============================================
			News List
============================================= */
.tg-content .tg-sectionhead{ text-align:left;}
.tg-newsposts.tg-liststyle,
.tg-liststyle .tg-news{ margin:0;}
.tg-liststyle .tg-news + .tg-news{ margin:30px 0 0;}
.tg-liststyle .tg-news figure{
	width:auto;
	float:left;
	margin:0 30px 0 0;
}
.tg-liststyle .tg-newscontent{
	width:auto;
	float:none;
	padding:0;
	margin:37px 0;
	overflow:hidden;
}
.tg-liststyle .tg-newscontent .tg-description{ padding:0 0 25px;}
.tg-newscontent .tg-btn{
	float:left;
	height:50px;
	line-height:46px;
	margin:0 0 30px;
}
.tg-newscontent .tg-btn span:before{line-height:46px;}
.tg-mostcommentslider .tg-newsmetadata li{
	padding:0;
	float:left;
	width:auto;
}
.tg-mostcommentslider .owl-controls{
	width:100%;
	float:left;
	line-height:12px;
	position:relative;
	padding:15px 0 30px;
}
/* =============================================
			News Detail
============================================= */
.tg-newsimg{ margin:0 0 30px;}
.tg-news-detail{
	width:100%;
	float:left;
	margin:0 0 80px;
	padding:0 0 80px;
	border-bottom:2px solid #ddd;
}
.tg-description blockquote{
	width:100%;
	float:left;
	margin:0;
	border:0;
	padding:80px 0;
	position:relative;
	text-align:center;
	font:bold 26px/33px 'Open Sans', Arial, Helvetica, sans-serif;
}
.tg-description blockquote:before,
.tg-description blockquote:after{
	left:50%;
	width:30px;
	height:30px;
	display:block;
	font-size:30px;
	line-height:30px;
	position:absolute;
	text-align:center;
	margin:0 0 0 -15px;
	font-family:'FontAwesome';
}
.tg-description blockquote:before{
	top:25px;
	content:'\f10d';
}
.tg-description blockquote:after{
	bottom:25px;
	content:'\f10e';
}
blockquote span{
	clear:both;
	display:block;
	padding:30px 0 0;
	font:400 14px/17px 'Open Sans', Arial, Helvetica, sans-serif;
}
blockquote span a{ color:#515151;}
blockquote q{
	width:100%;
	float:left;
	font-style:italic;
}
.tg-tags-social{
	width:100%;
	float:left;
	padding:20px 0;
	margin:0 0 30px;
	border-top:1px solid #ddd;
	border-bottom:1px solid #ddd;
}
.tg-tags{
	width:auto;
	margin:0;
	float:left;
}
.tg-tags-social i,
.tg-tags-social strong{
	font-size:14px;
	line-height:14px;
	color:#515151;
	text-transform:uppercase;
}
.tg-tags-social i{margin:0 5px 0 0;}
.tg-tags a{
	
	height:24px;
	color:#aeaeae;
	padding:0 10px;
	font-size:13px;
	background:#fff;
	line-height:23px;
	margin:0 0 0 5px;
	border-radius:3px;
	border:1px solid #ddd;
	text-transform:uppercase;
}
.tg-socialshare a,
.tg-socialshare i,
.tg-socialshare strong,
.tg-tags a,
.tg-tags i,
.tg-tags strong{float:left;}
.tg-socialshare > i,
.tg-socialshare strong,
.tg-tags i,
.tg-tags strong{ padding:5px 0;}
.tg-tags a:hover{ color:#fff;}
.tg-socialshare{ float:right;}
.tg-socialshare ul{
	width:auto;
	float:right;
	list-style:none;
}
.tg-socialshare ul li{
	width:30px;
	height:24px;
	float:left;
	overflow:hidden;
	margin:0 0 0 5px;
	list-style-type:none;
}
.tg-socialshare ul li:hover{ width:120px;}
.tg-socialshare li a{
	width:150px;
	height:24px;
	display:block;
	position:relative;
}
.tg-socialshare a i{
	margin:0;
	color:#999;
	float:left;
	width:30px;
	height:24px;
	display:block;
	font-size:13px;
	line-height:22px;
	text-align:center;
	border-radius:3px;
	border:1px solid #ddd;
}
.tg-socialshare ul li a i:after{
	top:0;
	left:0;
	color:#fff;
	width:120px;
	padding:0 10px;
	font-size:12px;
	line-height:24px;
	font-family: 'Oswald', Arial, Helvetica, sans-serif;
	position:absolute;
	background:#3b5998;
	content:attr(data-iconname);
	text-transform:uppercase;
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%);
}
.tg-socialshare ul li:hover a i:after{
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
}
.tg-socialshare ul li:nth-child(2) a i:after{background:#55acee;}
.tg-socialshare ul li:nth-child(3) a i:after{background:#007bb5;}
.tg-socialshare ul li:nth-child(4) a i:after{background:#cb2027;}
.tg-authorbox{
	width:100%;
	float:left;
	padding:25px;
	background:#f7f7f7;
}
.tg-authorpic{
	float:left;
	margin:0 25px 0 0;
	border:1px solid #ddd;
}
.tg-authorpic a{ display:block;}
.tg-authorpic a img{
	width:100%;
	height:auto;
	display:block;
}
.tg-authorinfo{ overflow:hidden;}
.tg-authorinfo h3{
	margin:0 0 27px;
	line-height:14px;
}
.tg-authorinfo .tg-description{ padding:0 0 27px;}
.tg-authorinfo .tg-description p{ margin:0;}
.tg-authorinfo .tg-socialicons{
	width:100%;
	float:left;
	padding:0;
}
.tg-authorinfo .tg-socialicons li:first-child{ padding-left:0;}
.tg-authorinfo .tg-socialicons li a{
	color:#515151;
	background:#fff;
	border-color:#ddd;
}
.tg-authorinfo .tg-socialicons li a:hover i{ color:#fff;}
.tg-comments{
	width:100%;
	float:left;
	padding:0 0 80px;
	border-bottom:2px solid #ddd;
}
.tg-comments ul{
	width:100%;
	float:left;
	list-style:none;
}
.tg-comments ul li{
	width:100%;
	float:left;
	padding:30px;
	list-style-type:none;
	border:1px solid #aeaeae;
}
.tg-comments ul li + li{ margin:10px 0 0;}
.tg-comment{
	width:100%;
	float:left;
}
.tg-comment figure{
	float:left;
	margin:0 30px 0 0;
}
.tg-comment figure a{ display:block;}
.tg-comment figure a img{ display:block;}
.tg-commentdata{
	overflow:hidden;
	position:relative;
}
.tg-commentdata .tg-datetag{
	display:block;
	font-size:13px;
	line-height:16px;
	padding:0 0 15px;
}
.tg-commentdata h3{
	margin:0 0 15px;
	font-size:18px;
	line-height:14px;
}
.tg-commentdata .tg-btn{
	padding:0 50px;
	height:50px;
	line-height:46px;
}
.tg-commentdata .tg-btn span:before{ line-height:46px;}
.tg-commentdata .tg-description{ padding:0 0 20px;}
.tg-commentdata .tg-description p{ margin:0;}
/* =============================================
			Team Detail
============================================= */
.tg-member.tg-detailpage{ margin:0;}
.tg-member .tg-sectionhead{ text-align:left;}
.tg-member.tg-detailpage .tg-description{
	border:0;
	padding:0 0 25px;
}
.tg-member.tg-detailpage figure{
	margin:0 0 30px;
	border-radius:5px;
}
.tg-member .tg-companylocationmap{
	width:100%;
	float:left;
	margin:0;
	height:360px;
	border-radius:5px;
	border:1px solid #ddd;
}
.tg-member.tg-detailpage .tg-memberinfo{
	padding:0;
	border:1px solid #ddd;
	border-bottom:0;
}
.tg-member.tg-detailpage .tg-memberinfo:before{ display:none;}
.tg-member.tg-detailpage .tg-memberinfo span{
	width:50%;
	float:left;
	padding:20px;
	border-bottom:1px solid #ddd;
}
.tg-member.tg-detailpage .tg-memberinfo span:nth-child(even){ background:#f7f7f7;}
.tg-member.tg-detailpage .tg-memberinfo span a{ color:#515151;}
.tg-memberinfo .tg-socialicons{
	float:left;
	padding:0;
	line-height:20px;
}
.tg-memberinfo .tg-socialicons li{
	padding:0 15px;
	position:relative;
	line-height:inherit;
}
.tg-memberinfo .tg-socialicons li:first-child{ padding-left:0;}
.tg-memberinfo .tg-socialicons li + li:before{
	top:0;
	left:0;
	content:'-';
	position:absolute;
}
.tg-memberinfo .tg-socialicons li a{
	width:auto;
	height:auto;
	border:0;
	border-radius:0;
	line-height:inherit;
}
.tg-memberinfo .tg-socialicons li a i{ line-height:inherit;}
/*=============================================
			Team Detail
=============================================*/
.tg-gallery{
	width:100%;
	float:left;
	margin:0 0 70px;
	position:relative;
	padding:20px 35px;
	background:#f7f7f7;
}
.tg-galleryslider{
	width:100%;
	float:left;
}
.tg-gallery .tg-project{
	width:100%;
	float:left;
}
.tg-gallery .tg-project figure figcaption{ padding:20px 20px 15px;}
.tg-gallery .tg-project figure figcaption .tg-datetag{ display:block;}
.tg-gallery .tg-project figure figcaption .tg-datetag a{ color:#fff;}
.tg-gallery .owl-controls{
	left:0;
	bottom:-70px;
	width:100%;
	height:auto;
	padding:0;
	position:absolute;
	line-height:12px;
}
.tg-btnview{
	top:50%;
	left:50%;
	z-index:2;
	color:#fff;
	width:40px;
	height:40px;
	display:block;
	overflow:hidden;
	line-height:36px;
	border-radius:50%;
	text-align:center;
	position:absolute;
	border:2px solid #fff;
	margin:-20px 0 0 -20px;
	opacity:0;
	visibility:hidden;
}
.tg-btnview i{
	color:#fff;
	display:block;
	line-height:inherit;
}
.tg-btnview:hover i{ color:#fff;}
.tg-gallery .tg-project:hover .tg-btnview{
	opacity:1;
	visibility:visible;
}
/*=============================================
			Contact Us
=============================================*/
.tg-locationmap{
	width:100%;
	float:left;
	height:500px;
}
.tg-themeform textarea{
	width:100%;
	float:left;
	resize:none;
	height:180px;
}
.tg-themeform .tg-btn{
	height:50px;
	line-height:46px;
}
.tg-themeform .tg-btn span:before{ line-height:46px;}
.tg-addressslider{
	width:100%;
	float:left;
}
.tg-addressslider .owl-item{padding:0 15px 30px;}
.tg-addressslider .item{
	width:100%;
	float:left;
}
.tg-addressbox{
	width:100%;
	float:left;
}
.tg-map{
	width:100%;
	float:left;
	overflow:hidden;
	position:relative;
	border-radius:5px;
}
.tg-addressmap{
	z-index:1;
	width:100%;
	float:left;
	height:180px;
	position:relative;
}
.tg-getdirection{
	top:0;
	left:0;
	z-index:2;
	width:100%;
	height:100%;
	position:absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-o-transform: translateY(100%);
	transform: translateY(100%);
}
.tg-btngetdirection{
	width:40px;
	height:40px;
	display:block;
	overflow:hidden;
	line-height:36px;
	border-radius:50%;
	text-align:center;
	border:2px solid #fff;
}
.tg-btngetdirection i{
	color:#fff;
	display:block;
	line-height:36px;
}
.tg-btngetdirection:hover i{ color:#fff;}
.tg-addresscontent{
	width:100%;
	float:left;
	position:relative;
	padding:20px 0 30px;
}
.tg-addresscontent:before{
	left:0;
	bottom:0;
	content:'';
	width:30px;
	height:2px;
	background:#ddd;
	position:absolute;
}
.tg-addresscontent h4{
	font-size:18px;
	line-height:14px;
	margin:0 0 20px;
}
.tg-addresscontent .tg-contactinfo,
.tg-addresscontent .tg-contactinfo a{ color:#515151;}
.tg-addresscontent .tg-contactinfo li + li{padding:20px 0 0;}
.tg-addressbox:hover .tg-getdirection{
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}
.tg-addressbox:hover .tg-addresscontent:before{ width:100%;}
/*=============================================
			404
=============================================*/
.tg-404{
	width:100%;
	float:left;
}
.tg-404content{
	width:100%;
	float:left;
	padding:0 0 80px;
	text-align:center;
}
.tg-404content h2{
	margin:0 0 80px;
	position:relative;
	line-height:26px;
	background:url(../images/img-06.png) no-repeat 50% 50%;
}
.tg-404content .tg-borderstyle{
	width:100%;
	float:left;
	margin:0 0 30px;
	padding:0 0 30px;
}
.tg-404content .tg-borderstyle:before{
	left:50%;
	width:170px;
	margin:0 0 0 -85px;
}
.tg-404content h2 .tg-svginject{
	margin:36px 0;
	position:static;
}
.tg-404content h3{
	font-size:38px;
	line-height:34px;
	margin:0 0 30px;
}
.tg-404content .tg-borderstyle p{
	margin:0;
	font-size:20px;
	line-height:23px;
}
.tg-404content span{
	width:100%;
	float:left;
	display:block;
	font-size:16px;
	line-height:20px;
}
.tg-404content span a{text-decoration:underline;}
.tg-404 form{
	padding:50px 35px;
	background:#f7f7f7;
}
.tg-404 form .tg-btn{
	width:100%;
	padding:0 29px;
}
/*=============================================
			Comming Soon
=============================================*/
body.tg-comingsoon-page {
	background: url(../images/bgcommingsoon.jpg) no-repeat fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position:relative;
	height: 100%;
}
.tg-commingsooncontent{
	width:100%;
	float:left;
	min-height:925px;
	position:relative;
}
.tg-logarea{
	width:100%;
	float:left;
	padding:50px 0;
	border-bottom:1px solid #fff;
}
.tg-logarea .tg-logo{
	width:301px;
	float:none;
	display:block;
	margin:0 auto;
}
.tg-textbox{
	color:#fff;
	width:100%;
	float:left;
	padding:50px 30px 0;
	text-align:center;
}
.tg-textbox h2{
	color:#fff;
	margin:0 0 7px;
	font-size:40px;
	line-height:36px;
}
.tg-textbox h3{
	color:#fff;
	margin:0 0 40px;
	font-size:24px;
	line-height:20px;
}
.tg-textbox .tg-description{ padding:0 0 50px;}
.tg-textbox .tg-description p{margin:0;}
.tg-countdown{
	width:100%;
	float:left;
	text-align:center;
}
.tg-countdown .clock-item{
	width:173px;
	margin:15px 30px;
	position:relative;
	display:inline-block;
	vertical-align:top
}
.tg-countdown .clock-item .text{
	top:0;
	left:0;
	width:100%;
	height:100%;
	position:absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.tg-countdown .clock-item .text .val{
	margin:0 0 10px;
	font-size:50px;
	line-height:40px;
}
.tg-countdown .clock-item .text .type-time{
	padding:0 10px;
	margin:0 auto;
	font-size:14px;
	line-height:30px;
	border-radius:3px;
	text-align:center;
	display:inline-block;
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}
.clock-canvas{
	width:100%;
	float:left;
	position:relative;
}
.clock-canvas:before{
	top:0;
	left:0;
	content:'';
	width:100%;
	height:100%;
	border-radius:50%;
	border:3px solid;
	position:absolute;
}
.clock-canvas .kineticjs-content{
	width:100%;
	height:100%;
	float:left;
}
body.tg-comingsoon-page .tg-copyright{
	left:0;
	bottom:0;
	width:100%;
	color: #fff;
	padding:0;
	line-height:50px;
	text-align:center;
	background:#515151;
	position:fixed;
}
/*=============================================
			Carrers
=============================================*/
.tg-carrers{
	width:100%;
	float:left;
}
.tg-carrers .tg-sectionhead{ text-align:left;}
.tg-accordion{
	width:100%;
	float:left;
}
.tg-panel{
	width:100%;
	float:left;
	overflow:hidden;
}
.tg-panel + .tg-panel{ margin:10px 0 0;}
.tg-panel h4 {
	margin:0;
	width:100%;
	float:left;
	padding:20px;
	color:#515151;
	cursor:pointer;
	background:#fff;
	font-size:16px;
	line-height:13px;
	border-radius:4px;
	position:relative;
	border:1px solid #ddd;
}
.tg-panel h4:before{
	top:0;
	right:30px;
	content:'\f107';
	position:absolute;
	line-height:53px;
	font-family:'FontAwesome';
}
.tg-panel h4.active:before{content:'\f106';}
.tg-accordion h4.active {
	color:#fff;
	background:#515151;
	border-color:#515151;
	border-radius:4px 4px 0 0;
}
.tg-panelcontent{
	width:100%;
	float:left;
	padding:30px;
	border:1px solid #ddd;
	border-top:0;
	border-radius:0 0 4px 4px;
}
.tg-joblocationmap{
	width:340px;
	height:185px;
	float:left;
	margin:0 30px 0 0;
}
.tg-jobdescription{ overflow:hidden;}
.tg-jobdescription h5{
	width:100%;
	float:left;
	margin:0 0 15px;
	font-size:18px;
	line-height:14px;
}
.tg-jobdescription ul{
	width:100%;
	float:left;
	list-style:none;
}
.tg-jobdescription ul li{
	width:100%;
	float:left;
	position:relative;
	padding:0 0 0 15px;
	list-style-type:none;
}
.tg-jobdescription ul li:before{
	top:0;
	left:0;
	content:'\f0da';
	position:absolute;
	font-family:'FontAwesome';
}
.tg-jobdescription ul li address{
	margin:0;
	font-style:normal;
}
.tg-jobdescription ul li a{}
/*=============================================
			Services
=============================================*/
.tg-content section:last-child{ padding-bottom:0;}
.tg-content section:first-child{ padding-top:0;}
.tg-content section + section{ border-top:2px solid #ddd;}
.tg-donutchartarea{
	width:100%;
	float:left;
}
.tg-chartContainer{
	width:100%;
	float:left;
}
.tg-chartContainer > div > div{ margin:0 auto;}
.tg-servicetabs{
	width:100%;
	float:left;
	position:relative;
	padding:0 0 0 70px;
}
.tg-navtab{
	top:-26px;
	left:45px;
	z-index:2;
	min-width:430px;
	list-style:none;
	position:absolute;
	text-transform:uppercase;
	font:14px/50px 'Montserrat', Arial, Helvetica, sans-serif;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-transform-origin: 0;
	-moz-transform-origin: 0;
	-ms-transform-origin: 0;
	-o-transform-origin: 0;
	transform-origin: 0;
}
.tg-navtab li{
	float:left;
	list-style:none;
	overflow: hidden;
	border-radius:3px;
	line-height:inherit;
	border:1px solid #ddd;
}
.tg-navtab li a{
	padding:0 15px;
	color:#aeaeae;
	display:block;
	line-height:inherit;
}
.tg-navtab li.active a,
.tg-navtab li:hover a{ color:#fff;}
.tg-tabcontent{
	width:100%;
	float:left;
	padding:30px;
	z-index:2;
	position:relative;
	border-radius:5px;
	border:1px solid #ddd;
}
.tg-tabcontent ul{
	width:50%;
	float:left;
	padding:0 0 30px;
	list-style:none;
}
.tg-tabcontent ul li{
	position:relative;
	padding:0 0 0 20px;
	list-style-type:none;
}
.tg-tabcontent ul li:before{
	top:0;
	left:0;
	content:'\f00c';
	position:absolute;
	font-family:'FontAwesome';
}
.tg-tabcontent .tg-tick li:before{content:'\f00c';}
.tg-tabcontent .tg-caret li:before{content:'\f0da';}
.tg-content .tg-services{
	width:auto;
	float:none;
	clear:both;
	margin:0 -15px;
	overflow:hidden;
}
.tg-content .tg-service{
	margin:0;
	height:290px;
	padding:15px;
}
.tg-content .owl-controls {
	width:100%;
	float:left;
	padding:30px 0 0;
	line-height:12px;
}
.tg-teamsidebarslider .owl-controls{
	width:100%;
	float:left;
	padding:15px 0 25px;
}
.tg-widget .tg-socialprofilelinks li{
	width:auto;
	float:none;
	padding:0;
	border:0;
}
.tg-widget .tg-socialprofilelinks li a{ color:#fff;}
.tg-siderbar .tg-widgettestimonials{border:0;}
.tg-testimonialsidebarslider .owl-item{ padding:0 1px 0 0;}
.tg-testimonialsidebarslider .tg-testimonial{
	text-align:center;
	margin:10px 0 57px;
	padding:120px 20px 80px;
}
.tg-testimonialsidebarslider .tg-clientinfo{
	left:20px;
	width:222px;
}
.tg-testimonialsidebarslider .tg-testimonial:before{
	top:50px;
	left:50%;
	width:56px;
	height:56px;
	line-height:56px;
	margin:0 0 0 -28px;
}
/*=============================================
			Modal Box
=============================================*/
.tg-requestcallbackmodal{top:50px;}
.tg-requestcallbackmodal .modal-dialog{width: 400px;}
.tg-modalcontent h4{
	margin: 0;
	width: 100%;
    float: left;
    color: #fff;
    padding: 20px;
	font-size: 15px;
    line-height: 15px;
    background: #282828;
}
.tg-modalcontent{
	width: 100%;
	float: left;
	background: #fff;
}
.tg-modalcontent form{
	width: 100%;
	float: left;
	padding: 20px;
}
.tg-modalcontent form fieldset{
	width: 100%;
	float: left;
	margin: 0;
	border: 0;
	padding: 0;
}
.tg-modalcontent form fieldset .form-group{
	width: 100%;
	float: left;
}

.owl-stage{
    margin: 0 auto;
}