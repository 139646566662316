@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?9e5tpn');
	src: url('../fonts/icomoon.eot?9e5tpn#iefix') format('embedded-opentype'),
		 url('../fonts/icomoon.ttf?9e5tpn') format('truetype'),
		 url('../fonts/icomoon.woff?9e5tpn') format('woff'),
		 url('../fonts/icomoon.svg?9e5tpn#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-Icon1:before {content: "\e900";}
.icon-Icon2:before {content: "\e901";}
.icon-Icon3:before {content: "\e902";}
.icon-Icon4:before {content: "\e902";}